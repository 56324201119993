/* START clickable link */
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: #4183c4;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
/* END clickable link */