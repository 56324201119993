body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overscroll-behavior: none;
}

h1, h2, h3, h4, h5 {
  font-family: 'Space Grotesk', sans-serif !important;
}

ol {
  list-style-type: decimal
}

ul {
  list-style-type: disc
}


/* START Temporary fix for issues introduced by 2.3.1 semantic-ui-css */
.ui.dimmer {
  display: flex !important;
}

.ui.modal {
  margin-top: 0;
}
/* END Temporary fix for issues introduced by 2.3.1 semantic-ui-css */

/* START Dropdown image class clash fix  */
.ui.dropdown .menu>.item>.file { width: auto }
/* END Redoc */


/* START stackable button groups */
@media only screen and (max-width: 767px) {
  .ui.stackable.buttons{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ui.stackable.buttons > .button{
    width: 100%;
  }
}
/* END stackable button groups */

/* START Menu width fix */
.ui.menu .dropdown.item .menu {min-width: max-content }
/* END Menu width fix */