/*******************************
         Site Overrides
Label source:
https://github.com/Semantic-Org/Semantic-UI-LESS/blob/2.5.0/definitions/elements/label.less
*******************************/

.ui.large.labels .label,
.ui.large.label {
  font-size: @large;
  text-transform: uppercase;
}
