/*******************************
         Site Overrides

Icon source: https://github.com/Semantic-Org/Semantic-UI-LESS/blob/2.5.0/definitions/elements/icon.less
*******************************/

i.large.icons {
  line-height: 0.6;
  vertical-align: middle;
  font-size: @large;
}