/*******************************
         Site Overrides

Table source below:
https://github.com/Semantic-Org/Semantic-UI-LESS/blob/2.5.0/definitions/collections/table.less
*******************************/

@everfoxPurple  : #7B54FC;

@selectableBackground: @everfoxPurple;

/* positive colour (green by default) */

@positiveBoxShadow : #AOAOAO;
@positiveBackgroundColor: #000;
@positiveColor: #fff;

@positiveBackgroundHover: #777;
@positiveColorHover: #fff;

.comparison-table {
  .ui.table& {
    border-collapse: collapse;
  }

  /* Row styling */

  .ui.table& tr.positive, .ui.table& td.positive {
      background: @everfoxPurple  !important;
      color: @positiveColor !important;
  }

  /* Border styling */

  /* Merges top row and headings */
  .ui.table& thead th {
    border-bottom: none;
  }

  /* Adds chunky line to the rest of the table cells */
  .ui.basic.table& tbody tr {
    border-bottom: 2px solid rgba(0, 0, 0, 0.55);
  }

  /* Coloured text over the purple veil */

  .ui.celled.table& tr td {
      color: #7b54fccc;
  }


  /* 45 degree angular styling of the table header */

  .ui[class*="very basic"].table:not(.sortable):not(.striped)& thead tr:first-child th {
      padding-top: 1.5em;
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%, 0 20%);
  }
}
