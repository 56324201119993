.slice-effect {
    position: relative;
    float: right;
    top: -1.6em;
    width: 4em;
    height: 4em;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 00%);
}

/* First three .slice-effect divs (first is blank) */
/* these three cells have 1em right padding, the last cell does not */
.header-row > th:nth-child(-n + 3) > .slice-effect {
    right: -1.0em;
}

/* Fourth and last .slice-effect - small tweak */
.header-row > th:nth-child(4) > .slice-effect {
    right: -0.25em;

}

.header-row > th > span {
    padding-left: 0.5em;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .header-row > th > span {
        padding-left: 0em;
    }
 }

.paddingLeft {
    padding-left: 1.2em !important;
}
