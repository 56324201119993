/* Mobile devices & tablets smaller than standard 1024 */
@media only screen and (max-width: 1023px) {
    .scroll-button {
        visibility: hidden !important;
    }
}
.scroll-button {
    text-align: center;
    margin-top: 15vh;
}

.scroll-button .angle.double.down.icon {
    /* Everfox Purple */
    background-color: #7B54FC !important;
}

.scroll-button .angle.double.up.icon {
    /* Everfox Purple */
    background-color: #7B54FC !important;
}

.fade-in {
    visibility: visible;
    opacity: 1;
    transition: opacity 500ms linear;
}

.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0ms 500ms, opacity 500ms linear;
}

.bouncing {
    -moz-animation: bouncing 3s infinite;
    -webkit-animation: bouncing 3s infinite;
    animation: bouncing 3s infinite;
}

@keyframes bouncing {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}