/*******************************
     User Global Variables
*******************************/

@everfoxBlack    : #000;
@black           : @everfoxBlack;
@everfoxWhite    : #FFF;
@white           : @everfoxWhite;

@fontName        : 'Space Grotesk';
@fontSmoothing   : antialiase
@headerFont      : @fontName, sans-serif;
@pageFont        : @fontName, sans-serif;

@everfoxPurple   : #7B54FC;
@purple          : @everfoxPurple;
@blue            : @everfoxPurple;

@everfoxCoral    : #FF5B42;
@orange          : @everfoxCoral;
@red             : @everfoxCoral;

@everfoxYellow   : #FFE92F;
@yellow          : @everfoxYellow;

@everfoxGreen    : #4C842A;
@olive           : @everfoxGreen;
@green           : @everfoxGreen;
@teal            : @everfoxGreen;


@everfoxDarkGray   : #414141;
@everfoxMediumGray : #A0A0A0;
@everfoxLightGray  : #CACACA;
@violet            : @everfoxDarkGray;
@pink              : @everfoxDarkGray;
@brown             : @everfoxDarkGray;
@grey              : @everfoxDarkGray;
@black             : @everfoxDarkGray;


/*----------
Text colour
----------*/

@textColor: @everfoxBlack;

/*-------------------
    Emotive Colors
https://github.com/Semantic-Org/Semantic-UI-LESS/blob/a46d6dcc7a42bdcec200f57b0491eb17718059a0/themes/default/globals/site.variables#L345
--------------------*/

/* Positive */
@positiveColor           : @green;
@positiveBackgroundColor : #FCFFF5;
@positiveBorderColor     : #A3C293;
@positiveHeaderColor     : #1A531B;
@positiveTextColor       : #2C662D;

/* Negative */
@negativeColor           : @red;
@negativeBackgroundColor : #FFF6F6;
@negativeBorderColor     : #E0B4B4;
@negativeHeaderColor     : #912D2B;
@negativeTextColor       : #9F3A38;

/* Info */
@infoColor              : @white;
@infoBackgroundColor    : @white;
@infoBorderColor        : @white;
@infoHeaderColor        : @black;
@infoTextColor          : @white;

/* Warning */
@warningColor           : @everfoxMediumGray;
@warningBorderColor     : @everfoxDarkGray;
@warningBackgroundColor : @everfoxMediumGray;
@warningHeaderColor     : @white;
@warningTextColor       : @white;

/* Success */
@successBackgroundColor : @everfoxMediumGray;
@successTextColor       : @everfoxBlack;
@successBoxShadow       : 0px 0px 0px 1px @everfoxDarkGray inset;
@attachedBoxShadow      : 0px 0px 0px 1px @everfoxDarkGray inset;
@greenBoxShadow         : 0px 0px 0px 1px @everfoxDarkGray inset;
@successHeaderColor     : @everfoxBlack;



/*--- Colored Backgrounds ---*/
/*
https://github.com/Semantic-Org/Semantic-UI-LESS/blob/a46d6dcc7a42bdcec200f57b0491eb17718059a0/themes/default/globals/site.variables#L259
*/

@redBackground    : lighten(@everfoxCoral, 20%);
@orangeBackground : lighten(@everfoxCoral, 20%);
@yellowBackground : lighten(@everfoxYellow, 20%);

@oliveBackground  : lighten(@everfoxGreen, 20%);
@greenBackground  : lighten(@everfoxGreen, 20%);
@tealBackground   : lighten(@everfoxPurple, 20%);

@blueBackground   : @everfoxDarkGray;

@violetBackground : lighten(@everfoxPurple, 20%);
@purpleBackground : lighten(@everfoxPurple, 20%);
@pinkBackground   : lighten(@everfoxCoral, 20%);
@brownBackground  : @yellowBackground;


/* controversial override, yellow or blue text is hard to read */

@yellowTextColor : @black;
@blueTextColor   : @white;