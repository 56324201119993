.eula {
    max-height: 60vh;
    overflow: auto;
}

.eula h1, .eula h2 {
    text-align: center;
    line-height: 1;
    margin: 0.3rem;
}

.eula .definition {
    font-weight: bold
}

.eula .point {
    font-weight: bold;
    margin-bottom: 1em;
    margin-top: 1em;
}

.eula .name {
    text-decoration: underline
}

.eula .name-no-underline {
    text-decoration: none;
}

.eula .subpoint {
    text-indent: 2em;
    margin-bottom: 0.4em;
}

.eula ol.list-lower-roman {
    list-style-type: lower-roman;
}

/* PYDOCX STYLES, TO BE REVIEWED */

.pydocx-caps {
    text-transform: uppercase
}

.pydocx-center {
    text-align: center
}

.pydocx-comment {
    color: blue
}

.pydocx-delete {
    color: red;
    text-decoration: line-through
}

.pydocx-hidden {
    visibility: hidden
}

.pydocx-insert {
    color: green
}

.pydocx-left {
    text-align: left
}

.pydocx-list-style-type-cardinalText {
    list-style-type: decimal
}

.pydocx-list-style-type-decimal {
    list-style-type: decimal
}

.pydocx-list-style-type-decimalEnclosedCircle {
    list-style-type: decimal
}

.pydocx-list-style-type-decimalEnclosedFullstop {
    list-style-type: decimal
}

.pydocx-list-style-type-decimalEnclosedParen {
    list-style-type: decimal
}

.pydocx-list-style-type-decimalZero {
    list-style-type: decimal-leading-zero
}

.pydocx-list-style-type-lowerLetter {
    list-style-type: lower-alpha
}

.pydocx-list-style-type-lowerRoman {
    list-style-type: lower-roman
}

.pydocx-list-style-type-none {
    list-style-type: none
}

.pydocx-list-style-type-ordinalText {
    list-style-type: decimal
}

.pydocx-list-style-type-upperLetter {
    list-style-type: upper-alpha
}

.pydocx-list-style-type-upperRoman {
    list-style-type: upper-roman
}

.pydocx-right {
    text-align: right
}

.pydocx-small-caps {
    font-variant: small-caps
}

.pydocx-strike {
    text-decoration: line-through
}

.pydocx-tab {
    display: inline-block;
    width: 4em
}

.pydocx-underline {
    text-decoration: underline
}
