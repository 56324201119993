/* .App {
} */


/* #476 note: there must be a way to set the blanket
default fonts in the semantic-ui less config, somewhere */
body,
.ui.accordion .title:not(.ui),
.ui.header,
.ui.button,
.ui.menu,
.ui .fluid .card,
.ui.card > .content > .header,
.ui.card > .content > .header > button {
	font-family: 'Space Grotesk', sans-serif !important;
}

@media only screen and (max-width: 1000px) {
	.ui.menu {
		display: block!important;
	}
}

h1.ui.header {
  color: #fff;
}

.page-header {
    /* A 'circle' background didn't seem to fit width
    the ever angular Everfox */
    /* background-image: url(images/circle.jpg); */

    background-color: #000;
    color: #fff;

    min-height: 15vh;
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1.5em;
    text-align: center;
}

.page-header .header-messages .message  {
  margin-top: 1.5em;
  max-width: 75%;
  text-align: left;
}

.top-menu {
	font-family: 'Space Grotesk', sans-serif;
	font-size: 1.2em;
	-webkit-box-shadow: none;
	box-shadow: none;
	border:none;
	border-radius:0.5rem;
    margin-top:2em !important;
    margin-bottom:2em !important;
}

.logo img {
    max-height: 3.5em !important;
}

.footer {
	width: 100%;
	height: 15vh;
  /* Everfox Coral */
	background-color: #FF5B42;
	text-align: center;
}

.App-logo {
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
  text-align: center;
}

.App-header h2 {
  margin: 0;
}

.App-content {
  padding: 1rem;
  min-height: 70vh;
}

pre {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

/* scale down and centre top left redoc logo */

.redoc-wrap img {
  max-width: 98px;
  margin-right: auto;
  margin-left: auto;
}

/* Hide the redocly banner scroll */

.redoc-wrap a > img {
  display: none;
}


/* hide the swagger version */
.redoc-wrap .api-content .api-info h1 span {
  display:none;
}

.footer:hover .logged-in-as {
  display:block;
}

.logged-in-as {
  display: none;
  color: lightgrey;
}

.dropdown.icon {
  color:#000;
}

ul.logo {
  padding-left: 0;
}

ul.logo li {
  list-style: none;
  padding-left: 2em;
  background: transparent url(images/arrow-bullet.svg) no-repeat scroll 0 0;
  /* background-color: #FF5B42; */
  background-size: 1em auto;
  background-position: 0 0.2em;
}

.account-switch {
  position: absolute;
  right: -5px;
  top: -2px;
}

.free_trial_status {
  text-align: center;
}

.ui.menu .ui.dropdown .menu>.active.item.dropdown {
  font-weight: 400 !important;
}

/* Style App.js wrapper */
.reports-page .codeSample {
  overflow: hidden;
}

/* Tab Container */
.reports-page .codeTabs {
  height: auto;
  background: #263238;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: rgb(204, 204, 204);
}

.reports-page h3.ui.header.codeHeader{
  color:#ffffff;
}

.reports-page .react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.reports-page .react-tabs__tab-list {
  margin: 0 auto 1rem;
  display: flex;
  border-radius: 1rem;
  padding-left: 0px;
}

.reports-page .react-tabs__tab {
  padding: 5px 10px;
  list-style: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid #000000;
  margin: 0px 3px 3px;
  background: rgb(17, 23, 26);
  font-size: 0.9em;
  font-weight: bold;
}

.reports-page .react-tabs__tab--selected {
  color: #000000;
  background: #ffffff;
}

.reports-page .react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.reports-page .react-tabs__tab {
  border-radius: 5px;
}

.reports-page .react-tabs__tab-panel {
  display: none;
}

.reports-page .react-tabs__tab-panel--selected {
  display: block;
  text-align: center;
}