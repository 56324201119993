.card-image {
    max-height:200px;
    min-height:120px;
    margin-top:3em;
    margin-bottom:3em;
}

.background-parent {
    display: block;
}

.background {

    width: 100vw;
    height: 100vh;

    /* note that iOS does not support 'fixed' */
    /* https://forums.developer.apple.com/forums/thread/99883?page=2 */
    background-image: url(images/home-page-ship-and-containers.jpg);
    background-size: cover;
    background-position: center;

    position: fixed;
    top: 0;
    bottom: 15vh;
    background-repeat: no-repeat;

    padding-bottom: 30vh !important;

    z-index: -1000;
}

.demo {
    display: flex;
    justify-content: center;
    padding-top:1em !important;
    min-height:10em !important;
}

.demo .info.message {
    text-align: center;
}

.freetrial-signup {
    display: flex;
    justify-content: center;
    padding-top:1em !important;
    text-align: center;
    padding-bottom: 5em !important;
}

.headers {
    padding-top:1em;
}

.headers h2 {
    font-weight: 600;
    font-size: 2.5rem;
    text-shadow: 0 0 0.5em #fff, 0 0 1em #fff, 0 0 2em #fff;
    color: #333;
    font-family: 'Space Grotesk', sans-serif !important;
}

.headers h3 {
    font-weight: 300;
    font-size: 2rem;
    text-shadow: 0 0 0.5em #fff, 0 0 1em #fff, 0 0 2em #fff;
    font-family: 'Space Grotesk', sans-serif !important;
    color: #333;
}

.home-actions {
    height: 100vh;
    padding-top: 10em !important;
    padding-bottom: 30vh !important;
}

.home .App-content {
    padding:0 !important;
}

.home .header a {
    color: rgba(0,0,0,.87) !important;
}

.dark {
    height: 100vh;
    margin-top:0 !important;
    margin-bottom:0 !important;
    padding-top: 15vh !important;
    color:#ffffffe6 !important;
}

.dark .header {
    /* Everfox Coral */
    color: #FF5B42 !important;
}

.light {
    min-height: 100vh;
    background-color:#fff !important;
    padding-top:5vh !important;
    margin-top:0 !important;
    margin-bottom:0 !important;
}

.light .grid {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.light .grid .imageCol {
    padding-left: 0 !important;
    padding-top:0 !important;
}

.light .grid .textCol {
    padding-top: 0 !important;
}

.apis {
    padding-top: 10vh !important;
    padding-bottom: 10vh !important;
}

.apis .header {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
}